import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {//登录
    path: '/login',
    name: 'login',
    component : () => import ('../views/login/Index.vue')
  },
  {//首页
    path: '/home',
    name: 'home',
    meta: { title: "首页" },
    component : () => import ('../views/home/Index.vue'),
    children:[
      {//学生
        path: '/student',
        name: 'student',
        meta: { title: "学生" },

        component: () => import ('../views/home/student/index.vue')
      },
      {//教师
        path: '/teacher',
        name: 'teacher',
        meta: { title: "教师" },

        component: () => import ('../views/home/teacher/index.vue')
      },
      {//论文
        path: '/researchFindings',
        name: 'researchFindings',
        meta: { title: "研究成果" },
        component: () => import ('../views/home/researchFindings/index.vue')
      },
      {//项目
        path: '/researchDirection',
        name: 'researchDirection',
        meta: { title: "研究方向" },
        component: () => import ('../views/home/researchDirection/index.vue')
      },

      {//用户
        path: '/medicine/user',
        name: 'user',
        meta: { title: "手机用户" },
        component : () => import ('../views/medicine/user/index.vue')
      },
      {//病人
        path: '/medicine/patient',
        name: 'patient',
        meta: { title: "病人" },
        component : () => import ('../views/medicine/patient/index.vue')
      },
      {//望诊
        path: '/medicine/wangzhen', // 望诊路由
        name: 'wangzhen',
        meta: { title: "望诊" },
        component: () => import('../views/medicine/wangzhen/index.vue'),
        children: [ // 望诊的子路由
          {
            path: 'face', // 面诊作为望诊的子路由
            name: 'face',
            meta: { title: "面诊" },
            component: () => import('../views/medicine/wangzhen/face.vue')
          },
          {
            path: 'ear', // 面诊作为望诊的子路由
            name: 'ear',
            meta: { title: "耳诊" },
            component: () => import('../views/medicine/wangzhen/ear.vue')
          },
          {
            path: 'hand', // 面诊作为望诊的子路由
            name: 'hand',
            meta: { title: "手诊" },
            component: () => import('../views/medicine/wangzhen/hand.vue')
          },
          {
            path: 'tongue', // 面诊作为望诊的子路由
            name: 'tongue',
            meta: { title: "舌诊" },
            component: () => import('../views/medicine/wangzhen/tongue.vue')
          },
        ]
      },
      {//问诊
        path: '/medicine/wenzhen', // 问诊路由
        name: 'wangzhen',
        meta: { title: "问诊" },
        component: () => import('../views/medicine/wenzhen/index.vue'),
        children: [ // 望诊的子路由
          {
            path: 'physique', // 体质作为问诊的子路由
            name: 'physique',
            meta: { title: "体质" },
            component: () => import('../views/medicine/wenzhen/physique.vue')
          },
          {
            path: 'rhinitis', // 鼻炎作为问诊的子路由
            name: 'rhinitis',
            meta: { title: "鼻炎" },
            component: () => import('../views/medicine/wenzhen/rhinitis.vue')
          }
        ]
      },
      {//望诊
        path: '/medicine/wen2zhen', // 闻诊路由
        name: 'wen2zhen',
        meta: { title: "闻诊" },
        component: () => import('../views/medicine/wen2zhen/index.vue'),
        children: [ // 望诊的子路由
          {
            path: 'voice', // 面诊作为望诊的子路由
            name: 'voice',
            meta: { title: "录音" },
            component: () => import('../views/medicine/wen2zhen/voice.vue')
          }
        ]
      },

    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
